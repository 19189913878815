import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoDiamond from "../components/LogoDiamond"
import { motion } from 'framer-motion';
import FadeUp from "../components/fadeUp";
import FeatherIcon from 'feather-icons-react';
import LogoTag from "../components/LogoTag"; 
import { Link} from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"


const IndexPage = () => (

  <Layout>
    <Seo title="Home" />
<Helmet>
  <body className="body-dark scroll-smooth"></body>
</Helmet>

<motion.section 
  className="grid grid-cols-1 h-screen splashheader flex content-between"
  initial={{y: 75, opacity: 0}}
  animate={{y: 0, opacity: 1}}
  transition={{duration: 1, type: "spring", ease: 'easeOut', bounce: 0.5 }} 
  id="top"
>
<div className="mt-48">
    <h1 className="w-full sm:w-1/3 display-1 mx-auto sm:text-5xl text-3xl my-8 p-16 sm:p-4 text-center text-owlwhite " >
    Great <span className="text-owlyellow">website</span> and <span className="text-owlyellow">logo</span> packages for small businesses.
    </h1>
</div>
    <AnchorLink to="#prices" className="w-8 h-12 mx-auto mb-16 animate-bounce">
      <LogoDiamond className="fill-owlred hover:fill-owlred-dark"/>  
    </AnchorLink>

</motion.section>          




  <section className="section feather-bg text-owlgreen h-auto py-32" id="prices"> 

        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

          <FadeUp className="h-96 rounded-md p-4 bg-owlgray border border-owlwhite shadow-lg grid grid-cols-1 content-between">                   
                
                <div>
                  <FeatherIcon icon="hexagon" size="48" stroke="#fff" className="float-right"/>         
                  <h1 className="display-1 text-4xl">Standard</h1>
                  <hr />                
                  <ol className="list-disc">
                      <li>Bespoke simple website with contact form</li>
                      <li>Individually designed</li>
                      <li>Hosting included</li>
                  </ol>
                </div>

                <div>
                  <h1 className="display-2 border-t border-b p-4 self-end">£450</h1>
                </div>

              </FadeUp>           
              
              <FadeUp className="h-96 rounded-md p-4 bg-owlgray border border-owlwhite shadow-lg grid grid-cols-1 content-between">   
                
                <div>
                  <FeatherIcon icon="hexagon" size="48" stroke="#fff" className="float-right"/>         
                  <h1 className="display-1 text-4xl">Premium</h1>
                  <hr className="border border-owlwhite border-b"/>                
                  <ol className="list-disc">
                      <li><h4 className="underline uppercase text-owlyellow">Everything from <strong>Standard</strong>, plus:</h4></li>                  
                      <li>Simple Logo </li>
                      <li>Advice on choice of website address, business name</li>
                  </ol>
                </div>

                <div>
                  <h1 className="display-2 border-t border-b p-4">£750</h1>                
                </div>

              </FadeUp>

              <FadeUp className="h-96 rounded-md p-4 bg-owlgray border border-owlwhite shadow-lg grid grid-cols-1 content-between">   

                <div>                              
                  <FeatherIcon icon="hexagon" size="48" stroke="#fff" className="float-right"/>         
                  <h1 className="display-1 text-4xl">Pro</h1>
                  <hr />                
                  <ol className="list-disc">
                    <li><h4 className="underline uppercase text-owlyellow">Everything from <strong>Premium</strong>, plus:</h4></li>   
                      <li>Getting started on social media &amp; google </li>
                      <li>Strategy for lead acquisition and tailoring website and social media  to meet the goals</li>
                  </ol>
                </div>

                <div>
                  <h1 className="display-2 border-t border-b p-4">£950</h1>                
                </div>
                
              </FadeUp>
          </div>      

          <FadeUp className="flex items-center justify-center mt-16">
            <Link to="/contact" className="btn-yellow w-48 py-4 mt-5  mr-2" target="_blank" rel="noreferrer">
              <div className="display-3">Get in touch
              <FeatherIcon icon="external-link" size="24" className="stroke-owlgrey -mt-0.5 ml-3 float-right"/>    
              </div>
            </Link>

            <Link to="/work" className="btn-yellow w-48 py-4 mt-5" target="_blank" rel="noreferrer">
              <div className="display-3">See our work
              <FeatherIcon icon="external-link" size="24" className="stroke-owlgrey -mt-0.5 ml-3 float-right"/>    
              </div>
            </Link>
          </FadeUp>  

        </div>
</section>    


<section>
    <FadeUp className="grid grid-cols-1 lg:grid-cols-3 gap-1">

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide lighthouse1"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%"}}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide lighthouse2"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide beagle"
     ></motion.div>  

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide lhg"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%"}}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide enista"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide together"
     ></motion.div>     

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide homera"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%"}}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide blacklake"
     ></motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#eee", backgroundSize: "60%" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "50%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="slide together2"
     ></motion.div>                   
                
    </FadeUp>  
</section>

<footer className="bg-owlgreen py-8 text-owlwhite">
  <div class="container grid grid-cols-2 gap-4 items-center">
    <div className="border-r border-owlwhite px-4">
      <AnchorLink to="#top">
      <LogoTag fill="#fff" stroke="#fff" className="w-14 float-right"/>
      </AnchorLink>
    </div>
    <div>
    <FeatherIcon icon="map-pin" size="24" stroke="#fff" className="mb-2 "/>      
    <p className="text-left m-0">
      The Owlery, <br />
      Bentside Road, <br />
      Disley SK12 2AJ</p>
    </div>  
  </div>
</footer>

</Layout>

)


export default IndexPage
